'use strict';

var Accordion = require('../improove/accordion');

var onAccordionToggle = function() {

}

var initializeEvents = function() {
    var $main = $('#main');

    // Initializes Accordion for Static Pages
    new Accordion(
    	$main.find('.static-page__accordion'),
        onAccordionToggle,
        { closeSiblings: true }
    );

};

exports.init = function() {
    initializeEvents();
};
