'use strict';

var dialog = require('./dialog'),
    page = require('./page'),
    validator = require('./validator');

var login = {
    /**
     * @private
     * @function
     * @description init events for the loginPage
     */
    init: function () {
        //o-auth binding for which icon is clicked
        $('.oAuthIcon').bind('click', function () {
            $('#OAuthProvider').val(this.id);
        });


        //toggle the value of the rememberme checkbox
        $('#dwfrm_login_rememberme').bind('change', function () {
            if ($('#dwfrm_login_rememberme').attr('checked')) {
                $('#rememberme').val('true');
            } else {
                $('#rememberme').val('false');
            }
        });

        // If it is an old customer, display the popup to reset password
        if (pageContext == "MyAccount") {
            if (window.IsOldCustomer) {
                openResetPasswordPopup('password-reset-dialog password-reset-old-customer');
            }
        }

        $('#password-reset').on('click', function (e) {
            e.preventDefault();
            openResetPasswordPopup('password-reset-dialog');
        });
    }
}

/**
 * @function
 * @description Open the Reset Password Popup Dialog
 * @param {String} Dialog CSS Class Name
 */
function openResetPasswordPopup(dialogCSSClassName) {
    dialog.open({
        url: $('#password-reset').attr('href'),
        options: {
            dialogClass: dialogCSSClassName,
            open: function () {
                if (!IsOldCustomer) {
                    validator.init();
                }

                var $requestPasswordForm = $('[name$="_requestpassword"]');
                var $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');

                $($submit).on('click', function (e) {
                    if (!$requestPasswordForm.valid()) {
                        return;
                    }

                    e.preventDefault();

                    var data = $requestPasswordForm.serialize();
                    // add form action to data
                    data += '&' + $submit.attr('name') + '=';

                    // make sure the server knows this is an ajax request
                    if (data.indexOf('ajax') === -1) {
                        data += '&format=ajax';
                    }

                    $.ajax({
                        type: 'POST',
                        url: $requestPasswordForm.attr('action'),
                        data: data,
                        success: function (response) {
                            if (typeof response === 'object' &&
                                    !response.success &&
                                    response.error === 'CSRF Token Mismatch') {
                                page.redirect(Urls.csrffailed);
                            } else if (typeof response === 'string') {
                                dialog.$container.html(response);
                            }
                        },
                        failure: function () {
                            dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
                        }
                    });
                });
            }
        }
    });
}

module.exports = login;
