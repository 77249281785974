/**
 * @namespace Improove
 */
(function(exports, doc, $) {
    'use strict';

    exports.Improove = exports.Improove || {};

    /**
     * Object with a collection of recurring Helper-functions
     *
     * @namespace Helpers
     * @memberOf Improove
     */
    exports.Improove.Helpers = {

        /**
         * Set a Cookie
         *
         * @param {String} key
         * @param {String} value
         */
        setCookie: function(key, value) {
            var expires = new Date();
            expires.setTime(expires.getTime() + (1 * 24 * 60 * 60 * 1000));
            document.cookie = encodeURIComponent(key) + '=' +
                encodeURIComponent(value) + ';expires=' + expires.toUTCString() + "; path=/";
        },

        /**
         * Retrieve a Cookie
         *
         * @param {String} key
         * @return {String}
         */
        getCookie: function(key) {
            var keyEQ = encodeURIComponent(key),
                keyValue = document.cookie.match('(^|;) ?' + keyEQ +
                    '=([^;]*)(;|$)');
            return keyValue ? decodeURIComponent(keyValue[2]) : null;
        }

    };

})(window, document, window.jQuery);
