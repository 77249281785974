'use strict';

var util = require('./util'),
    states = require('./improove/states');

var timer = {
    id: null,
    clear: function () {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function (duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};

var minicart = {
    init: function () {
        states.add('cart', $('.state-container--cart'), $('.cart-state-trigger'), {
            onActivate: function() {
                $('.header').addClass('fixed-header');
            },
            onDeactivate: function() {
                var scrollYInit = window.pageYOffset;

                if (scrollYInit == 0) {
                    $('.header').removeClass('fixed-header');
                }
            }
        });

        var self = this;
        this.$el = $('#mini-cart');
        this.$content = this.$el.find('.mini-cart-content');

        $('#mini-cart').on('click', '.remove-button', function(event) {
            event.preventDefault();

            var url = util.ajaxUrl(this.href);
            
            $.ajax({
                type: 'GET',
                url: url,
                success: function (response) {
                    document.dispatchEvent(new CustomEvent('GTM-push-removeFromCart', {detail: {
                        element: event.target
                    }}));
                    if (response && response.length > 0) {
                        self.$content.html(response);
                        self.updateQty();
                    }
                }
            });
        });

        $('#mini-cart').on('click', '.mini-cart-link-checkout', function() {
            $('.mini-cart-link-checkout').attr('disabled', true);
            $('.mini-cart-checkout-text').addClass('visually-hidden');
            $('.mini-cart-loading-text').removeClass('visually-hidden');
        });
    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
    show: function (html, event) {
        this.$content.html(html);
        this.updateQty();
        states.toggle('cart', event);
    },
    /**
     * Update Quantity Indicator in Header
     *
     * @function
     */
    updateQty: function() {
        var iconValue = $('.cart-state-trigger .minicart-quantity');
        var minicartContentValue = $('.state-container--cart  .mini-cart-items-number');

        var newIconValue = isNaN(parseInt(minicartContentValue.text(), 10)) ? 0 : parseInt(minicartContentValue.text(), 10);

        iconValue.text(newIconValue);
    },
};

module.exports = minicart;
