'use strict';

var _ = require('lodash'),
    ajax = require('../../../ajax');

var FormPrepare = function(opts) {
    if (!opts.formSelector || !opts.continueSelector) {
        throw new Error('Missing form and continue action selectors.');
    }
    this.formEl = $(opts.formSelector);
    this.continueEl = $(opts.continueSelector);
    this.validator = this.formEl.validate();
    this.requiredInputs = $('.required', this.formEl).find(':input');
    this.validateForm();
    this.bindEvents();
};

FormPrepare.prototype = {

    /** @type {?Element} */
    formEl: null,

    /** @type {?Element} */
    continueEl: null,

    /** @type {?Object} */
    validator: null,

    /** @type {?Object} */
    requiredInputs: null,

    // /** @type {?Object} */
    // avataxValidation: null,

    /**
     * Bind Events
     *
     */
    bindEvents: function() {
        var _this = this;
        this.requiredInputs.on('change', function() {
            _this.validateEl(this);
        });
        this.requiredInputs.filter('input').on(
            'keyup', _.debounce(function() {
                _this.validateEl(this);
            }, 200));
    },

    /**
     * Check if form has empty required fields
     *
     * @return {Boolean}
     */
    hasEmptyRequired: function() {
        // filter out only the visible fields
        var requiredValues = this.requiredInputs.filter(':visible').map(
            function() {
                return $(this).val();
            }
        );
        return _(requiredValues).contains('');
    },

    /**
     * Validate Form
     *
     * @return {Bool}
     */
    validateForm: function() {
        var _this = this;
        // only validate form when all required fields are filled to avoid
        // throwing errors on empty form
        if (!this.validator) {
            return false;
        }
        if (!this.hasEmptyRequired()) {
            if (this.validator.form()) {
                // Klarna
                if (this.formEl.find('#klarnaCountry') &&
                    $('#klarnaCountry').val() === 'SE' &&
                    $('.klarnaSelectedPaymentMethodID').is(':checked') &&
                    $('button[name$="billing_save"]').length > 0
                ) {
                    if ($('#authenticated').val() === 'true') {
                        $('.pno').off('keyup').on('keyup', function() {
                            if ($(this).val().length === 0) {
                                _this.continueEl.attr('disabled', 'disabled');
                                console.log(this.formEl, 'pno was changed');
                                return false;
                            } else {
                                _this.continueEl.removeAttr('disabled');
                                return true;
                            }
                        });
                    } else if ($('.klarna-addresses-container')
                                .find('select:visible').length > 0
                    ) {
                        this.continueEl.removeAttr('disabled');
                        return true;
                    } else {
                        this.continueEl.attr('disabled', 'disabled');
                        $(window).trigger('addmessage', {
                            sticky: true,
                            text: 'You must select an address for Klarna',
                            type: 'error',
                        });
                        return false;
                    }
                } else {
                    this.continueEl.removeAttr('disabled');
                    return true;
                }
                // End Klarna
            }
        } else {
            if (this.continueEl.selector != '[name$="billing_save"]') {
                this.continueEl.attr('disabled', 'disabled');
            }
            return false;
        }
    },

    /**
     * Validare Single Form El
     *
     * @param {Element} element
     */
    validateEl: function(element) {

        if ($(element).val() === '' && this.continueEl.selector != '[name$="billing_save"]') {
            this.continueEl.attr('disabled', 'disabled');
        } else {
            // enable continue button on last required field that is valid
            // only validate single field
            if (this.validator.element($(element)) &&
                !this.hasEmptyRequired()
            ) {
                this.continueEl.removeAttr('disabled');
            } else {
                this.continueEl.selector != '[name$="billing_save"]' && this.continueEl.attr('disabled', 'disabled');
            }
        }
    },

    /**
     * @function
     * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
     * based on the merchandise in the cart and the currently entered shipping address
     * (the address may be only partially entered).  If the list of applicable shipping methods
     * has changed because new address information has been entered, then issue another AJAX
     * request which updates the currently selected shipping method (if needed) and also updates
     * the UI.
     */
    avataxValidation: function()
    {
        return true;
        // var _this = this;
        // var url = Urls.avataxValidateAddress; // Resources.ds

        // // These attribute are written differently depending on if the site is b2b or regular
        // var country_code =
        //     _this.formEl.find("#dwfrm_singleshipping_shippingAddress_addressFields_siteCountries_country").val() ?
        //     _this.formEl.find("#dwfrm_singleshipping_shippingAddress_addressFields_siteCountries_country").val() :
        //     _this.formEl.find("#dwfrm_singleshipping_shippingAddress_addressFields_countryCode").val();

        // var state_code =
        //     _this.formEl.find("#dwfrm_singleshipping_shippingAddress_addressFields_states_state").val() ?
        //     _this.formEl.find("#dwfrm_singleshipping_shippingAddress_addressFields_states_state").val() :
        //     _this.formEl.find("#dwfrm_singleshipping_shippingAddress_addressFields_stateCode").val();

        // // mimics a customer address
        // var data =
        // {
        //     address1: _this.formEl.find("#dwfrm_singleshipping_shippingAddress_addressFields_address1").val(),
        //     address2: "",
        //     city: _this.formEl.find("#dwfrm_singleshipping_shippingAddress_addressFields_city").val(),
        //     companyName: "",
        //     countryCode: country_code,
        //     firstName: _this.formEl.find("#dwfrm_singleshipping_shippingAddress_addressFields_firstName").val(),
        //     fullName: "",
        //     ID: "",
        //     jobTitle: "",
        //     lastName: _this.formEl.find("#dwfrm_singleshipping_shippingAddress_addressFields_lastName").val(),
        //     phone: _this.formEl.find("#dwfrm_singleshipping_shippingAddress_addressFields_phone").val(),
        //     postalCode: _this.formEl.find("#dwfrm_singleshipping_shippingAddress_addressFields_postal").val(),
        //     postBox: "",
        //     salutation: "",
        //     secondName: "",
        //     stateCode: state_code,
        //     suffix: "",
        //     suite: "",
        //     title: ""
        // };

        // //return $.ajax({
        // //     type: 'POST',
        // //     url: url,
        // //     dataType: "json",
        // //     data: data,
        // //     async: false
        // // });

    }
};

module.exports = FormPrepare;
