'use strict';

var ajax = require('../../ajax'),
    image = require('./image'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    _ = require('lodash');


/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (href) {
     
    var $pdpForm = $('.pdpForm');
    var qty = $pdpForm.find('input[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
    };

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: $('#product-content'),
        callback: function () {
            if (SitePreferences.STORE_PICKUP) {
                productStoreInventory.init();
            }
            image.replaceImages();
            tooltip.init();

            $(document).trigger('variant:load');

            if (SitePreferences.TRUSTPILOT_ENABLED) {
                //Call the function to refresh the MiniTrustbox as variant change on PDP
                refreshTrustpilotMiniWidgets();
            }

            // SmartOrderRefill Option Selected By Default
            if ($('#pdpMain').find('#OsfSor_refill_product_yes').length) {
                $('#pdpMain').find('#OsfSor_refill_product_yes').prop("checked", true);
                $('#pdpMain').find('.smart-order-refill-period').attr('style', 'display: block;');
            }
            // disabled first option of dropdown Diaper size after select a size
            if (!$('#va-naty-diaper-size').find('.emptytext').is(':selected')){
                $('#va-naty-diaper-size').find('.emptytext').prop('disabled', true);
            }

            variationChangeView();
            weekIntervalMobile();
            stickyProductDetails(); 
        }
    });
};

//Refresh the Trustpilot Mini Widget
var refreshTrustpilotMiniWidgets = function() {
	const trustbox = document.getElementById('trustboxMini');
    window.Trustpilot.loadFromElement(trustbox);
};

// change from dropdown to buttons - variation
var variationChangeView = function() {
    var variationItem = $('.variation-select-items-wrapper ul li');
    var variationItemCharacter = $(".variation-select-items-wrapper ul li:contains(()");
    
    $(variationItem).on('click', function () {
        var selectedItem = $(this).attr('data-value');
        $($(this).closest('.value').find('.variation-select-wrapper .variation-select')).val(selectedItem).change();
    });
    
    // Word breakdown for variation buttons
    $(variationItemCharacter).html(function(){
        return $(this).html().replace("(","<span>(")
    });
}

// change from buttons to dropdown - week interval
var weekIntervalMobile = function() {
    var weekItem = $('#OsfSorDeliveryWeekIntervalDropdown select');
    $(weekItem).on('change', function () {
        var optionSelected = $("option:selected", this);
        var dataID = optionSelected.attr('data-id');

        $('#' + dataID + ' input').click();
    });
}

// Stick Product Details
var stickyProductDetails = function() {
    if ($('.product-detail-inner').length && util.getScreenSize() == 'large') {
        var el = $('.product-detail-inner');
        var headerHeight = $('.header').innerHeight() + 20;
        var stickyStop = 60;
        var stickyHeight = $('.product-detail-inner').innerHeight();
        var stickyWidth = $('.product-detail-inner').innerWidth();

        $(window).scroll(function() {
            var limit = $('footer').offset().top - stickyHeight - headerHeight - 20;
            var windowTop = $(window).scrollTop();

            if (stickyStop < windowTop) {
            el.css({
                position: 'fixed',
                top: headerHeight,
                width: stickyWidth
            });
            } else {
                el.css({
                    position: 'static',
                    width: stickyWidth
                });
            }
            if (limit < windowTop) {
            var diff = limit - windowTop;

            el.css({
                top: diff
            });
            }
        });
    }
}

module.exports = function () {
    var $pdpMain = $('#pdpMain');

    // change drop down variation attribute - should replace product content with new variant
    $pdpMain.on('change', '.variation-select', function () {
        if ($(this).val().length === 0) { return; }
        updateContent($(this).val());

        // ViewContent event for Facebook conversion
        try {
            if ($('.product-detail-page').length > 0 && SitePreferences.FACEBOOK_CONVERSION_ENABLED && window.fbViewContentEventID != undefined) {
                // calls Facebook-SendFacebookConversion controller
                setTimeout(function() {
                    var productId = $('#product-content .gtm-product-data').data('id');
                    var eventName = 'ViewContent';
                    $.ajax({
                        url: Urls.sendFacebookConversion,
                        type:'GET',
                        data: {'fbPixelEventId': window.fbViewContentEventID, 'eventName': eventName, 'productID': productId},
                        success: function (response) {
                        },
                        error: function(err) {
                        }
                    });
                }, 2000);
            }
        } catch (e) {}
    });

    // change radio button - package variation
    $pdpMain.on('change', 'input[name="input-radio-naty-diapering-package"]', function () {
        if ($(this).val().length === 0) { return; }
        updateContent($(this).val());
    });

    // change radio button - product type
    $pdpMain.on('click', '.product-detail__product-type-option', function (e) {
        e.preventDefault();

        var $productTypeOption = $(this);
        var otherURL = '';
    
        if ($productTypeOption.find('input').val().length === 0) { return; }
    
        if ($productTypeOption.find('input').prop('id') == 'product-type-subscription-input') {
            otherURL = $productTypeOption.find('input').val();
            window.location.href = otherURL;
        } else if ($productTypeOption.find('input').prop('id') == 'product-type-single-input') { 
            otherURL = $productTypeOption.find('input').val().split('-S');
            window.location.href = otherURL[0] + otherURL[1];
        }
    });

    // change radio button - week interval
    $pdpMain.on('change', 'input[name="OsfSorDeliveryWeekInterval"]', function () {       
        var parent = $(this).closest('.form-row');
        parent.siblings().removeClass('checked');

        $(this).closest('.form-row').addClass('checked');
    });

    $(window).on('load resize', _.debounce(function () {
        stickyProductDetails(); 
    }, 500));
    
    variationChangeView();
    weekIntervalMobile();
};
