'use strict';

var ajax = require('../../../ajax'),
    FormPrepare = require('./formPrepare'),
    util = require('../../../util'),
    adyenCheckout = require('../../../adyen-checkout');


var Billing = function(containerEl) {
    this.containerEl = containerEl;
    this.init();
};

Billing.prototype = {

    /** @type {?Element} */
    containerEl: null,

    /** @type {Array} */
    addressFieldsEl: [],

    /** @type {?Element} */
    paymentMethodsEl: null,

    /** @type {?Element} */
    formEl: null,

    /** @type {?Element} */
    shippingFormEl: null,

    /** @type {?Object} */
    formPrepare: null,

    /** @type {?Element} */
    addCouponEl: null,

    /** @type {?Element} */
    couponCodeEl: null,

    /** @type {?Element} */
    addressSelectEl: null,

    /** @type {?Element} */
    emailFieldsEl: null,

    /* @type {Number} */
    requests: 0,

    /**
     * Init
     *
     */
    init: function() {
        this.initElements();
        this.initForm();
        this.setInitialPaymentMethod();
        this.bindEvents();
    },

    /** 
     * Initialize Elements
     */
    initElements: function() {
        this.formEl = this.containerEl.find('.checkout-billing');
        this.paymentMethodsEl = this.containerEl.find('.payment-method');
        this.paymentMethodListEl =
            this.containerEl.find('.payment-method-options');
        this.addCouponEl = $('#add-coupon');
        this.couponCodeEl = this.containerEl.find('input[name$="_couponCode"]');
        this.addressSelectEl =
            this.containerEl.find('select[name$="_billing_addressList"]');
        this.emailFieldsEl = this.containerEl.find('.billing-email-fields');

        this.shippingFormEl = $('.checkout-shipping');

        // Adyen
        var $selectPaymentMethod = $('.payment-method-options');
        var selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();
        var $payType = $('[name="brandCode"]');
        var $issuer = $('.issuer');
        var selectedPayType = $payType.find(':checked').val()
        this.payType = $payType;
        this.issuerId = this.containerEl.find('[name="issuerId"]');
        this.issuer = $issuer;
        this.selectedPayType = selectedPayType;
        this.selectedIssuerId = this.issuerId.find(':checked').val();
        // End Adyen

        this.couponCodeEl.val('');
    },

    /**
     * Initialize Form
     *
     */
    initForm: function() {
        this.formPrepare = new FormPrepare({
            formSelector: 'form[id$="billing"]',
            continueSelector: '[name$="billing_save"]'
        });
    },

    /**
     * Set Initial Payment Method
     */
    setInitialPaymentMethod: function() {
        var selectedPaymentMethod =
            this.paymentMethodListEl.find(':checked').val();
        this.updatePaymentMethod((selectedPaymentMethod) ?
                selectedPaymentMethod : 'Adyen');

        // by default, set as selected the first payment type
        if (this.payType[0]) {
        	this.updatePaymentType(this.payType[0].value, true);
        }
    },

    /**
     * Bind Events
     *
     */
    bindEvents: function() {
        var _this = this;

        $(document).off('checkout:billing-updated');
        $(document).on('checkout:billing-updated', this.init.bind(this));
        $(document).off('checkout:update-billing');
        $(document).on('checkout:update-billing', this.update.bind(this));
        $(document).off('checkout:validate-billing');
        $(document).on('checkout:validate-billing', function(event){
            _this.formPrepare.validateForm();
        });

        this.paymentMethodListEl.on('click', 'input[type="radio"]', function() {

            _this.updatePaymentMethod($(this).val());

            // Adyen
            if ($(this).val() == 'Adyen' || $(this).val() == 'AdyenComponent' && _this.payType.length > 0) {
                // set payment type of Adyen to the first one
                _this.updatePaymentType((_this.selectedPayType) ? _this.selectedPayType : _this.payType[0].value, false);
            } else {
                _this.payType.removeAttr('checked');
            }
            // End Adyen
        });

        // Ayden
        this.payType.on('click', function() {
            _this.updatePaymentType($(this).val(), false);
            // if the payment type contains issuerId fields, expand form with
            // the values
            if ($(this).siblings('#issuer').length > 0) {
                _this.issuer.show();
                _this.updatePaymentType(
                    (_this.selectedIssuerId) ? _this.selectedIssuerId : _this.issuerId[0].value, true);
            } else {
                _this.issuer.hide();
                $('input[name="issuerId"]').removeAttr('checked');
            }
        });

        this.issuerId.on('click', function() {
            _this.updatePaymentType($(this).val(), true);
        });
        // End Adyen

        this.addCouponEl.on('click', function(event) {
            event.preventDefault();
            _this.addCoupon();
        });

        this.couponCodeEl.on('keydown', function(event) {
            if (event.which === 13) {
                event.preventDefault();
                _this.addCoupon();
            }
        });

        $('.remove-coupon').on('click', function(event) {
            event.preventDefault();
            _this.removeCoupon(this);
        });

        if (SitePreferences.ADYEN_SF_ENABLED) {
            adyenCheckout.initBilling();
        }

        this.payType.on('change', function() {
            $('#selectedIssuer').val('');
            _this.issuer.hide();
            $('.checkoutComponent').hide();
            $('#component_' + $(this).val()).show();
            if ($(this).siblings('.issuer').length > 0) {
                $('#selectedIssuer').val($(this).siblings('.issuer').val());
                $(this).siblings('.issuer').show();
            }
        });

        // Paypal
        // Do not submit billing when paypal method is clicked
        /*$('input#is-PayPal.input-radio',this.formEl).on('click', function(event) {
            event.preventDefault();
        });*/
        // END Paypal
    },

    /**
     * Validate Entire Form
     *
     * @return {Boolean}
     */
    validate: function() {
        return this.formPrepare.validateForm();
    },

    /**
     * Update Billing
     *
     */
    update: function() {
        $(document).trigger('checkout:step-request', {step: 'billing'});
        $.ajax({
            type: 'GET',
            url: this.formEl.attr('action'),
            success: function(data) {
                $(document).trigger(
                    'checkout:handle-response', {
                        step: 'billing', response: data, request: 'GET'});
            }
        });
    },

    /**
     * Save Billing
     *
     */
    saveBilling: function() {
        var _this = this,
            isPayPalActive =
                $('input#is-PayPal.input-radio', _this.formEl).is(':checked') == true && paypal !== undefined;

        if (this.formEl.validate().checkForm()) {
            var data = this.formEl.serialize();
            if (data.indexOf('ajax') === -1) {
                data += '&format=ajax';
            }
            $(document).trigger('checkout:step-request', {step: 'billing'});
            if (isPayPalActive) {
                // Opens the paypal in-context-checkout popup
                // (needs to happend on user action or it gets blocked)
                paypal.checkout.initXO();
            }
            $.ajax({
                type: 'POST',
                url: this.formEl.attr('action'),
                data: data,
                success: function(data) {
                    if (isPayPalActive) {
                        // Fills paypal popup with token-url:
                        paypal.checkout.startFlow(data);
                    } else {
                        $(document).trigger(
                            'checkout:handle-response', {
                                step: 'summary', response: data});
                    }
                }
            });
        } else {
            $(document).trigger('checkout:step-dirty', {step: 'billing'});
        }
    },

    /**
     * Changes the payment method form depending on the passed paymentMethodID
     *
     * @param {String} paymentMethodID the ID of the payment method
     */
    updatePaymentMethod: function(paymentMethodID) {
        this.paymentMethodsEl.removeClass('payment-method-expanded');
        var $selectedPaymentMethod =
            this.paymentMethodsEl.filter(
                '[data-method="' + paymentMethodID + '"]');
        if ($selectedPaymentMethod.length === 0) {
            $selectedPaymentMethod = $('[data-method="AdyenComponent"]');
        }
        $selectedPaymentMethod.addClass('payment-method-expanded');

        this.formPrepare.validateForm();
    },

    /**
     * Adyen
     * Changes the payment type or issuerId of the selected payment method
     *
     * @param {String, Boolean} value of payment type or issuerId and a test value to see which one it is, to which the payment type or issuerId should be changed to
     */
    updatePaymentType: function(selectedPayType, issuerType) {
        if (issuerType) {
            $('#dwfrm_adyPaydata_issuer').val(selectedPayType);
        } else {
            $('input[name="brandCode"]').removeAttr('checked');
            $('input[value=' + selectedPayType + ']').prop('checked', 'checked');
        }
        // if the payment type has hidden fields reveal it
        $('#component_' + selectedPayType).show();
        this.formPrepare.validateForm();
    },

    /**
     * Add Coupon
     *
     */
    addCoupon: function() {
        var _this = this,
            $error = this.formEl.find('.coupon-error'),
            $success = this.formEl.find('.coupon-success'),
            code = this.couponCodeEl.val();
        if (code.length === 0) {
            $error.html(Resources.COUPON_CODE_MISSING);
            return;
        }
        $error.addClass('empty');
        $error.html('');
        $success.addClass('empty');
        $success.html('');
        var url = util.appendParamsToUrl(
                Urls.addCoupon, {couponCode: code, format: 'ajax'});
        $.getJSON(url, function(data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else {
                msg = data.message
                    .split('<')
                    .join('&lt;')
                    .split('>')
                    .join('&gt;');
                if (!data.success) {
                    fail = true;
                }
            }
            if (fail) {
                $error.removeClass('empty');
                $error.html(msg);
                return;
            }
            if (data.success) {
                $success.removeClass('empty');
                $success.html('');
                _this.update();
            }

            // basket check for displaying the payment section, if the adjusted
            // total of the basket is 0 after applying the coupon
            // this will force a page refresh to display the coupon message
            // based on a parameter message
            if (data.success && data.baskettotal === 0) {
                this.saveBilling();
            }
        });
    },

    removeCoupon: function(element) {
        var _this = this,
            code = $(element).data('val');
        var url = util.appendParamsToUrl(Urls.removeCoupon, {couponCode: code, format: 'ajax'});
        $.getJSON(url, function(data) {
            if (data.success) {
                _this.update();
            }
        });
    }
};

module.exports = Billing;
