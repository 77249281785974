'use strict';

var ajax = require('../../../ajax'),
    FormPrepare = require('./formPrepare'),
    Address = require('./address'),
    util = require('../../../util'),
    url = require('url');

/**
 * Shipping
 *
 * @param {Element} containerEl
 */
var Shipping = function(containerEl) {
    this.containerEl = containerEl;
    this.init();
};

Shipping.prototype = {

    /** @type {?Element} */
    containerEl: null,

    /** @type {?Object} */
    shippingMethods: null,

    /** @type {?Element} */
    shippingMethodList: null,

    /** @type {?Element} */
    addressFieldsEl: null,

    /** @type {?Element} */
    formEl: null,

    /** @type {?Element} */
    errorEl: null,

    /** @type {?Object} */
    formPrepare: null,

    /** @type {Boolean} */
    useAddressForBilling: false,

    /** @type {?Element} */
    sameAsShipping: null,

    /** @type {?Element} */
    sameAsShippingElement: null,

    /* @type {Number} */
    requests: 0,

    /**
     * Initialize
     *
     */
    init: function() {
        this.initElements();
        this.initForm();
        this.bindEvents();
        this.shippingMethods = '';
        this.updateShippingMethodList();
        this.disableButton();
    },

    /**
     * Init Elements
     *
     */
    initElements: function() {
        this.addressFieldsEl = this.containerEl.find('.form-list--dynamic');
        this.shippingMethodList = $('#shipping-method-list');
        this.formEl = this.containerEl.find('.checkout-shipping');
        this.errorEl = $('[id$="checkout-validation-error-message"]');
        this.sameAsShippingElement = $('.billing_sameAsShippingSelector');
        this.sameAsShipping = $('#billing_sameAsShipping');
    },

    /**
     * Initialize Shipping Form
     *
     */
    initForm: function() {
        this.formPrepare = new FormPrepare({
            continueSelector: '[name$=" "]',
            formSelector: '[id$="singleshipping_shippingAddress"]'
        });
        this.address = new Address(this.formEl);
    },

    /**
     * Bind Events
     *
     * 
     */
    bindEvents: function() {
        var _this = this;

        $(document).off('checkout:shipping-updated');
        $(document).on('checkout:shipping-updated', this.init.bind(this));
        $(document).off('checkout:update-shipping');
        $(document).on('checkout:update-shipping', this.update.bind(this));

        // Make sure fields are validated on change and blur (for autocomplete)
        this.formEl.on('change blur', function(event) {
            _this.formPrepare.validateEl(event.target);
        });

        this.formEl.submit(function(event) {
            event.preventDefault();
// && _this.validateAvatax()
            if (_this.validate()) {
                document.dispatchEvent(new CustomEvent('GTM-push-checkoutStep', {detail: {
                    step: 2
                }}));
                _this.saveShipping();
            }
            else {
                util.scrollBrowser(0);
            }
        });

        // When changing last address field, validate entire form
        this.addressFieldsEl.find('.input-text.required').last().on(
            'change', function() {
                _this.validate();
            });

        this.formEl.on('change blur',
            'input[name$="_addressFields_address1"], input[name$="_addressFields_address2"], select[name$="_addressFields_siteCountries_country"], select[name$="_addressFields_states_state"], input[name$="_addressFields_city"], input[name$="_addressFields_zip"]',
            function(event) {
                _this.updateShippingMethodList();
            });

        this.sameAsShippingElement.on('click', function(event){
            event.preventDefault();
            _this.updateBillingAddressForm();
        });

    },

    /**
     * disable button if you are on B2B site 
     */
     disableButton: function() {
        if (window.location.href.indexOf('b2b') > -1) {
            $('.billing_sameAsShippingSelector').unbind('click');
        }
    },

    /**
     * Validate Entire Form
     *
     * @return {Boolean}
     */
    validate: function() {
        return this.formPrepare.validateForm();
    },

    validateAvatax: function() {
        var _this = this;
        var success = true;

        // var request = this.formPrepare.avataxValidation();
        // request.success(function (data) {
        //     // data.ReasonCode must exist because locales that are not using Avalara will not get a 
        //     // response object but something different entierly.
        //     // In other words we make sure that we only fail unsuccessful requests to Avalara at
        //     // this point.
        //     if (data.ReasonCode && data.ReasonCode !== 'Success') {
        //         success = false;
        //     }
        // });

        // // Should the error message be shown or not
        // if (!success) {
        //     _this.errorEl.removeClass('visually-hidden');
        // } else {
        //     _this.errorEl.addClass('visually-hidden');
        // }

        return success;
    },

    /**
     * Save Shipping From Address Select
     *
     */
    saveShippingfromSelected: function() {
        var _this = this;
        var data = this.formEl.serialize();
        if (data.indexOf('ajax') === -1) {
            data += '&format=ajax';
        }
        $.ajax({
            type: 'POST',
            url: this.formEl.attr('action'),
            data: data,
            success: function(data) {
                _this.updateSummary();
            }
        });

    },

    /**
     * Save Shipping
     *
     */
    saveShipping: function() {
        var _this = this;
        if (_this.formEl.validate().checkForm()) {
            var disabled = _this.formEl.find(':input:disabled,select:disabled').removeAttr('disabled');
            var data = _this.formEl.serialize();
            disabled.attr('disabled','disabled');

            if (data.indexOf('ajax') === -1) {
                data += '&format=ajax';
            }
            $(document).trigger('checkout:step-request', {step: 'shipping'});
            $.ajax({
                type: 'POST',
                url: _this.formEl.attr('action'),
                data: data,
                success: function(data) {
                    $(document).trigger(
                            'checkout:handle-response', {
                                step: 'billing', response: data});
                    _this.updateSummary();
                }
            });
            
        } 
        else {
            $(document).trigger('checkout:step-dirty', {step: 'shipping'});
        }


    },

    /**
     * Update Shipping
     *
     */
    update: function() {
        $(document).trigger('checkout:step-request', {step: 'shipping'});
        $.ajax({
            type: 'GET',
            url: this.formEl.attr('action'),
            success: function(data) {
                $(document).trigger(
                    'checkout:handle-response', {
                        step: 'shipping', response: data, request: 'GET'});
            }
        });
    },

    /**
     * Get Shipping Method Url
     *
     * @param {String} url
     * @param {Array} extraParams
     * @return {String}
     */
    getShippingMethodURL: function(url, extraParams) {
        var params = {
            address1: this.formEl.find('input[name$="_address1"]').val(),
            address2: this.formEl.find('input[name$="_address2"]').val(),
            countryCode: this.formEl.find('select[id$="_country"]').val(),
            stateCode: this.formEl.find('select[id$="_state"]').val(),
            postalCode: this.formEl.find('input[name$="_postal"]').val(),
            city: this.formEl.find('input[name$="_city"]').val()
        };
        return util.appendParamsToUrl(url, $.extend(params, extraParams));
    },

    /**
     * Select shipping Method
     *
     * @param {String} shippingMethodID
     */
    selectShippingMethod: function(shippingMethodID) {
        var _this = this;
        if (!shippingMethodID) {
            return;
        }
        // attempt to set shipping method
        var url = this.getShippingMethodURL(
                Urls.selectShippingMethodsList,
                {shippingMethodID: shippingMethodID}
            );
        ajax.getJson({
            url: url,
            callback: function (data) {
                _this.updateSummary();
                if (!data || !data.shippingMethodID) {
                    window.alert('Couldn\'t select shipping method.');
                    return false;
                }
                // display promotion in UI and update the summary section,
                // if some promotions were applied
                $('.shippingpromotions').empty();
            }
        });
    },

    /**
     * Update Shipping Method List
     *
     */
    updateShippingMethodList: function() {
        var _this = this,
            url;
        if (!this.shippingMethodList || this.shippingMethodList.length === 0) {
            return;
        }
        url = this.getShippingMethodURL(Urls.shippingMethodsJSON);
        ajax.getJson({
            url: url,
            callback: function(data) {
                if (!data) {
                    window.alert(
                        'Couldn\'t get list of applicable shipping methods.');
                    return false;
                }
                if (_this.shippingMethods &&
                    _this.shippingMethods.toString() === data.toString()
                ) {
                    return true;
                }
                // We need to update the UI.  The list has changed.
                // Cache the array of returned shipping methods.
                _this.shippingMethods = data;
                // load the shipping method form
                var smlUrl =
                    _this.getShippingMethodURL(Urls.shippingMethodsList);
                _this.shippingMethodList.load(smlUrl, function() {
                    _this.shippingMethodList.fadeIn('fast');
                    // rebind the radio buttons onclick function to a handler.
                    _this.shippingMethodList.find('[name$="_shippingMethodID"]')
                        .on('click', function() {
                            _this.selectShippingMethod(
                                $(this).val());
                        });

                    // update the summary
                    _this.updateSummary();
                    // if nothing is selected in the shipping methods select
                    // the first one
                    if (_this.shippingMethodList
                            .find('.input-radio:checked').length === 0
                    ) {
                        _this.shippingMethodList.find('.input-radio:first')
                            .prop('checked', 'checked');
                        _this.selectShippingMethod(
                            _this.shippingMethodList.find('.input-radio:first')
                                .val());
                    }
                });
            }
        });
    },

    /**
     * Update Summary
     *
     */
    updateSummary: function() {
        $(document).trigger('checkout:update-summary');
    },

    updateBillingAddressForm: function() {
        if (this.sameAsShipping.is(':checked')) {
            this.sameAsShipping.prop('checked',false);
            $('input[name*="_useAsBillingAddress"]').val('false');
        } else {
            this.sameAsShipping.prop('checked',true);
            $('input[name*="_useAsBillingAddress"]').val('true');
        }
       
        $('#billing_formcontainer').toggleClass('hidden');
    },
};

module.exports = Shipping;
