'use strict';

/**
 * Accordion
 *
 * @class Accordion
 *
 * @param {Element} accordionEl
 * @param {?Function} toggleCb callback when toggling accordion
 */
var Accordion = function(accordionEl, toggleCb, options) {
    this.accordionEl = accordionEl;
    if (this.accordionEl) {
        this.items = this.accordionEl.find('.accordion__item');
        if (typeof toggleCb === 'function') {
            this.toggleCb = toggleCb;
        }
    }
    if (typeof options === 'object') {
        this.options = $.extend({}, this.options, options);
    }
    this.bindEvents();
};

Accordion.prototype = {

    /** @type {?Element} */
    accordionEl: null,

    /** @type {?Array} */
    items: [],

    /** @type {?Function} */
    toggleCb: null,

    /** @type {Object} */
    options: {
        closeSiblings: false
    },

    /**
     * Bind Events
     *
     */
    bindEvents: function() {
        this.items.on(
            'click', '.accordion__item-heading', this.toggle.bind(this));
    },

    /**
     * Toggle Accordion Item
     *
     * @param {Event} event
     */
    toggle: function(event) {
        var item = $(event.currentTarget).closest('.accordion__item');
        if ($(item).hasClass('accordion__item--active')) {
            this.close(item);
        } else {
            this.open(item);
        }
        if (this.toggleCb) {
            this.toggleCb();
        }
    },

    /**
     * Open Accordion Item
     *
     * @param {Element} item
     */
    open: function(item) {
        if (this.options.closeSiblings) {
            this.items.removeClass('accordion__item--active');
        }
        $(item).addClass('accordion__item--active');
    },

    /**
     * Close Accordion Item
     *
     * @param {Element} item
     */
    close: function(item) {
        $(item).removeClass('accordion__item--active');
    }
};

module.exports = Accordion;
