
'use strict';

var windowScroll = function() {
    this.bindEvents();
};

windowScroll.prototype = {

    /** @type {Array} */
    callbacks: [],

    /**
     * Bindd Events
     *
     */
    bindEvents: function() {
        //window.requestAnimationFrame(this.scrollObserver.bind(this));
        $(window).on('scroll', this.scrollObserver.bind(this));
    },

    /**
     * Scroll Observer
     *
     */
    scrollObserver: function() {
        window.requestAnimationFrame(this.scrollAction.bind(this));
    },

    /**
     * Scroll Action
     *
     */
    scrollAction: function() {
        $.each(this.callbacks, function(i, callback) {
            if (callback && typeof callback === 'function') {
                callback();
            }
        });
    },

    /**
     * Add Callback
     *
     * @param {Function} callback
     * @return {Number} index of callback function (for removal)
     */
    addCallback: function(callback) {
        var arrayLength;
        if (callback && typeof callback === 'function') {
            arrayLength = this.callbacks.push(callback);
        }

        return arrayLength - 1;
    },

    /**
     * Removes Callbacks by index
     * we do not splice then from the array or the callbacks added later than
     * the removed one would not have the same position
     *
     * @param {Number} arrayIndex
     */
    removeCallback: function(arrayIndex) {
        this.callbacks[arrayIndex] = '';
    }
};

module.exports = new windowScroll();
