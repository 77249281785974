'use strict';

var states = require('./states');

var menu = {};

menu.init = function init() {

    states.add('menu', $('.state-container--menu'), $('.menu-state-trigger'), {});

    var links = $('#navigation a.has-sub-menu');

    links.each(function() {
        $(this).click(function(e) {            
            // Define class name
            var classShortName = '',
                classLongName = '';

            if($(this).hasClass('level-1-item')) {
                classShortName = '.level-2';
                classLongName = 'level-2-active';
            } else if ($(this).hasClass('level-2-item')) {
                classShortName = '.level-3';
                classLongName = 'level-3-active';
            }

        	// Mobile
        	if($(window).width() < 1200) {
                e.preventDefault();
        		var navigation = $(this).closest('#navigation');
                var li = $(this).closest('li');
                $(this).next(classShortName).addClass('active');
                navigation.addClass(classLongName);
                navigation.scrollTop(0);
                li.addClass(classLongName);
        	}
        });
    });

    // Back Buttons for Mobile
    $('.level-2-back').click(function() {
        var navigation = $(this).closest('#navigation');
        var li = $(this).closest('li');
        navigation.removeClass('level-2-active');
        li.removeClass('level-2-active');
        navigation.find('.level-2').removeClass('active');
    });

    $('.level-3-back').click(function() {
        var navigation = $(this).closest('#navigation');
        var li = $(this).closest('li');
        navigation.removeClass('level-3-active');
        li.removeClass('level-3-active');
        navigation.find('.level-3').removeClass('active');
    });
};

module.exports = menu;
