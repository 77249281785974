'use strict';

var dialog = require('../../dialog'),
    minicart = require('../../minicart'),
    page = require('../../page'),
    util = require('../../util'),
    Promise = require('promise'),
    _ = require('lodash');

// send conversion pixel
function sendAddToCartPixel () {
    try {
        setTimeout(function() {
            var eventName = 'AddToCart';
            var productId = $('#pid').val();
            var productQuantity = $('.product-add-to-cart #Quantity').val();
            if (window.fbAddToCartEventID != undefined) {
                $.ajax({
                    url:Urls.sendFacebookConversion,
                    type:'GET',
                    data: {'fbPixelEventId': window.fbAddToCartEventID, 'eventName': eventName, 'productID': productId, 'productQuantity' : productQuantity},
                    success: function (response) {}
                });
            }
        }, 2000);
    } catch (error) {}
}


/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function(form) {
    var $form = $(form),
        $qty = $form.find('input[name="Quantity"]');
    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1');
    }

    $form.find('.add-to-cart').attr('disabled', true);
    $form.find('.add-to-cart-text').addClass('visually-hidden');
    $form.find('.add-to-cart-loading').removeClass('visually-hidden');

    document.dispatchEvent(
        new CustomEvent('GTM-push-addToCart', {
            detail: {
                element: $form.find('.add-to-cart')[0]
            }
        })
    );

    // triggers AddtoCart event for facebook conversion
    if (SitePreferences.FACEBOOK_CONVERSION_ENABLED) {
        sendAddToCartPixel();
    }

    return Promise.resolve(
        $.ajax({
            type: 'POST',
            url: util.ajaxUrl(Urls.addProduct),
            data: $form.serialize()
        })
    ).then(function(response) {
        // handle error in the response
        if (response.error) {
            throw new Error(response.error);
        } else {
            $form.find('.add-to-cart').attr('disabled', false);
            $form.find('.add-to-cart-text').removeClass('visually-hidden');
            $form.find('.add-to-cart-loading').addClass('visually-hidden');
            return response;
        }
    });
};

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function(e) {
    e.preventDefault();

    var $form = $(this).closest('form');

    addItemToCart($form).then(
        function(response) {
            var $uuid = $form.find('input[name="uuid"]');
            if ($uuid.length > 0 && $uuid.val().length > 0) {
                page.refresh();
            } else {
                minicart.show(response, e);
            }
        }.bind(this)
    );
};

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function(e) {
    e.preventDefault();
    var $productForms = $('#product-set-list')
        .find('form')
        .toArray();
    Promise.all(_.map($productForms, addItemToCart)).then(function(responses) {
        dialog.close();
        // show the final response only, which would include all the other items
        minicart.show(responses[responses.length - 1]);
    });
};
/**
 * @description handler to handle error message when size of product is not selected
 */
var addToCartErroMsg = function() {
    var productV = $('.product-variations');
    $('.variation-select-wrapper')
        .find('select.variation-invalid')
        .addClass('error');
    productV
        .find('#errorMsgContainer')
        .removeClass('hidden')
        .addClass('error');
    productV.find('.label.variation-invalid , .value.variation-invalid').addClass('error');
};

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
module.exports = function() {
    $('.add-to-cart[disabled]').attr('title', $('.availability-msg').text());
    $('.product-detail').on('click', '.add-to-cart-error', addToCartErroMsg);
    $('.product-detail').on('click', '.add-to-cart', addToCart);
    $('.product-detail-page__product-recommendations').on('click', '.add-to-cart', addToCart);
    $('#add-all-to-cart').on('click', addAllToCart);
};
