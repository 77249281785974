'use strict';

function init() {

    // Hide error in form input
    $('.footer-newsletter__form .field-wrapper input').on('focusout', function (e) {
        $('.newsletter-valid-email-error').remove();
        $('.footer-newsletter__form .field-wrapper input').removeClass('error');
    });

    // Newsletter 'Sign up' button
    $('.footer-newsletter-button').on('click', function (e) {
        e.preventDefault();

        var newsletterForm = $('form[id$="newsletter"]');
        var submit = newsletterForm.find('[name$="newsletter"]');

        if (!newsletterForm.valid()) {
            return;
        }

        var data = newsletterForm.serialize();
        // Add form action to data
        data += '&' + submit.attr('name') + '=';

        // Add ajax format to data
        if (data.indexOf('ajax') === -1) {
            data += '&format=ajax';
        }

        $.ajax({
            type: 'GET',
            url: Urls.marketingCloudNewsletterSubscribe,
            data: data,
            success: function (response) {
                if (typeof response === 'object') {
                    if (response.success) {
                        $('.footer-newsletter__form-wrapper').html('<div class="newsletter-success-msg">' + Resources.NEWSLETTER_SUCCESS_MSG + '</div>');
                    } else {
                        $('.footer-newsletter__form-wrapper').html('<div class="newsletter-error-msg">' + Resources.NEWSLETTER_ERROR_MSG1 + ' ' + Resources.NEWSLETTER_ERROR_MSG2 + '</div>');
                    }
                } else if (typeof response === 'string') {
                    // Show error message
                    $('.footer-newsletter__form-wrapper').html('<div class="newsletter-error-msg">' + Resources.NEWSLETTER_ERROR_MSG1 + ' ' + Resources.NEWSLETTER_ERROR_MSG2 + '</div>');
                }
            },
            fail: function () {
                // Show error message
                $('.footer-newsletter__form-wrapper').html('<div class="newsletter-error-msg">' + Resources.NEWSLETTER_ERROR_MSG1 + ' ' + Resources.NEWSLETTER_ERROR_MSG2 + '</div>');
            }
        });

    });

}

module.exports = {
    init: init
};
