'use strict';

var Address = require('./address'),
    Customer = require('./customer'),
    Billing = require('./billing'),
    Shipping = require('./shipping'),
    utils = require('../../../util');

/**
 * Checkout Accordion
 *
 * @param {Object} steps
 * @param {Object} steps.stepname
 */
var CheckoutAccordion = function(steps) {
    this.init(steps);
};

CheckoutAccordion.prototype = {
    steps: {},
    activeStep: null,

    init: function(steps) {
        this.steps = steps;
        this.bindEvents();
        this.goToNextStep();
    },

    bindEvents: function() {
        var _this = this;
        $(document).on('checkout:handle-response', function(event, data) {
            _this.handleResponse(event, data);
        });
        $(document).on('checkout:step-request', function(event, data) {
            _this.handleRequest(event, data);
        });
        $('#main').on('click', '.checkout__go-back',
            this.goToPreviousStep.bind(this));

        $('input[name$="_useAsBillingAddress"]').prop('checked', true);

        $(document).on('click', '.checkout-step-completed .edit-link', function (e) {
            e.preventDefault();
            var step = $(this).data('step');
            _this.goToStep(step);
        });
        $(document).on('checkout:update-summary', function(event, data) {
            $.ajax({
                type: 'POST',
                url: Urls.summaryRefreshURL,
                success: function(data) {
                    $('.checkout-summary').html(data);
                }
            });
        });
    },

    handleResponse: function(event, data) {

        var html = $.parseHTML(data.response, document, true),
            stepName = this.getStepNameFromHtml(data.response),
            nextStep;

        if (stepName) {
            nextStep = this.getNextStep(data.step);
            // update step in response and activate:
            this.steps[stepName].containerEl.html(html);
            this.steps[stepName].requests--;
            if (typeof data.request === 'undefined' || data.request !== 'GET') {
                this.goToStep(stepName);
            }
            $(document).trigger('checkout:' + stepName + '-updated');
            if (stepName !== data.step) {
                // update initiating step:
                $(document).trigger('checkout:update-' + data.step);
            }
            if (stepName !== data.step) {
                this.steps[data.step].requests--;
            }
            if (nextStep !== stepName) {

            }
            $(document).trigger('checkout:update-summary');
        } else {
            window.location.href = Urls.cartShow;
        }
        this.trackCheckoutSteps(this.getStepNumber(data.step));
    },

    /**
     * Try to parse step name from html
     *
     * @param {String} html
     * @return {String|null}
     */
    getStepNameFromHtml: function(html) {
        var stepName;
        if ($(html).filter('#checkout-customer').length > 0) {
            stepName = 'customer';
        } else if ($(html).filter('#checkout-shipping').length > 0) {
            stepName = 'shipping';
        } else if ($(html).filter('#checkout-billing').length > 0) {
            stepName = 'billing';
        } else {
            stepName = null;
        }
        return stepName;
    },

    /**
     * Handle step requests
     *
     * @param {Event} event
     * @param {Object} data
     */
    handleRequest: function(event, data) {
        var nextStep;
        if (data.step) {
            nextStep = this.getNextStep(data.step);
            this.steps[data.step].requests++;
            if (nextStep) {
                this.steps[nextStep].requests++;
            }
        }
    },

    /**
     * Add and Remove loading indicators from steps
     *
     */
    addRemoveLoadingIndicators: function() {
        $.each(this.steps, function(i, step) {
            if (step.requests && step.requests > 0) {
                step.containerEl.addClass('checkout__step-container--loading');
            } else {
                step.containerEl.removeClass(
                    'checkout__step-container--loading');
            }
        });
    },

    goToStep: function(stepName) {
        if (!stepName) {
            return;
        }
        // Lock all steps
        $.each(this.steps, function(i, step) {
            if (!step.containerEl.hasClass(
                'checkout__step-container--hidden')
            ) {
                step.containerEl.addClass(
                    'checkout__step-container--hidden');
            }
        });
        this.steps[stepName].containerEl.removeClass(
            'checkout__step-container--hidden');
        this.activeStep = stepName;

        var offsetValue = 115;
        if($(window).width() >= 1200) {
        	offsetValue = 160;
    	}

        utils.scrollBrowser(this.steps[stepName].containerEl.offset().top - offsetValue);
    },

    /**
     * Go To next step in checkout
     *
     */
    goToNextStep: function() {
        var keys = Object.keys(this.steps);
        if (this.activeStep) {
            this.gotoStep(this.getNextStep(this.activeStep));
        } else {
            this.goToStep(keys[0]);
        }
    },

    /**
     * Go To Previous Step in checkout
     *
     */
    goToPreviousStep: function() {
        var keys = Object.keys(this.steps);
        if (this.activeStep) {
            this.goToStep(this.getPreviousStep(this.activeStep));
        } else {
            this.goToStep(keys[0]);
        }
    },

    /**
     * Get the name of the next step
     *
     * @param {String} stepName
     * @return {String|false}
     */
    getNextStep: function(stepName) {
        var keys = Object.keys(this.steps),
            index = keys.indexOf(stepName);
        if ((index > -1) && index < keys.length - 1) {
            return keys[index + 1];
        }
        return false;
    },

    /**
     * Get the name of the next step
     *
     * @param {String} stepName
     * @return {String|false}
     */
    getPreviousStep: function(stepName) {
        var keys = Object.keys(this.steps),
            index = keys.indexOf(stepName);
        if (index > 0) {
            return keys[index - 1];
        }
        return false;
    },

    /**
     * Get Step Number
     *
     * @param {String} stepName
     * @return {Number}
     */
    getStepNumber: function(stepName) {
        var keys = Object.keys(this.steps),
            stepNumber = keys.indexOf(stepName) + 1;
        return stepNumber;
    },

    /**
     * Add Checkout steps to datalayer
     *
     * @param {Number} stepNumber
     * @param {Function} callback
     */
    trackCheckoutSteps: function(stepNumber, callback) {
        if (stepNumber) {
            /*
        	dataLayer.push({
                'event': 'checkout',
                'ecommerce': {
                    'checkout': {
                        'actionField': {
                            'step': stepNumber,
                            'option': ''
                        },
                        'products': window.cartProducts || [],
                    }
                },
               'eventCallback': function() {
                  if (callback && typeof callback === 'function') {
                    callback();
                  }
               }
            });
            */
        }
    }
};


/**
 * Initializes the Onepage Checkout
 */
exports.init = function() {
    var steps = {
        customer: new Customer($('#onepage-customer-container')),
        shipping: new Shipping($('#onepage-shipping-container')),
        billing: new Billing($('#onepage-billing-container'))
    };

    var checkoutAccordion = new CheckoutAccordion(steps);
};
