'use strict';

var util = require('../util');

function init() {

    var headerElement = $('.header');
    var noticeBannerEle = document.querySelector('.notice-banner');

    // Set Header on Page Init
    var scrollYInit = window.pageYOffset;

    if(scrollYInit > 0) {
        headerElement.addClass('fixed-header');
    }

    // Set Header on Page Scroll
    $(window).scroll(function() {
        var scrollY = $(window).scrollTop();

        if (scrollY > 0) {
            headerElement.addClass('fixed-header');
            if (noticeBannerEle !== null) {
                noticeBannerEle.style.setProperty('visibility','hidden');
            }
        } else {
            headerElement.removeClass('fixed-header');
            if (noticeBannerEle !== null) {
                noticeBannerEle.style.setProperty('visibility','visible');
            }
        }
    });

    if(util.getScreenSize() == 'large') {
        // Hover for Menu Navigation Level 1 Items
        $("#navigation .level-1 > li").hover(function() {
                if($(this).find('.level-1-item.has-sub-menu').length > 0) {
                    headerElement.addClass('fixed-header');
                }
            }, function(){
                var scrollYInit = window.pageYOffset;
                var isStateActive = $('body').is('[class^=state--]');

                if(scrollYInit == 0 && isStateActive == false) {
                    headerElement.removeClass('fixed-header');
                }
        });
    }

    $("#header-slider").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1219,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    slidesToScroll: 1,
                }
            }
        ]
    });

}

module.exports = {
    init: init
};