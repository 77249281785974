'use strict';

var util = require('../../../util');

var Address = function(formEl) {
    this.formEl = formEl;
    this.bindEvents();
};

Address.prototype = {

    /** @type {?Element} */
    formEl: null,

    /**
     * Bind Events
     *
     */
    bindEvents: function() {
        var _this = this;
        $('select[name$="_addressList"]', this.formEl).on('change',
            function(event) {
                event.preventDefault();
                var selected = $(this).children(':selected').first();
                var selectedAddress = $(selected).data('address');
                if (!selectedAddress) {
                    return false;
                }
                util.fillAddressFields(selectedAddress, _this.formEl);
            }
        );

    }

};

module.exports = Address;
