'use strict';

var ajax = require('../../../ajax'),
    FormPrepare = require('./formPrepare'),
    util = require('../../../util'),
    url = require('url');

/**
 * Customer
 *
 * @param {Element} containerEl
 */
var Customer = function(containerEl) {
    this.containerEl = containerEl;
    this.init();
};

Customer.prototype = {

    /** @type {?Element} */
    containerEl: null,

    /** @type {?Element} */
    customerFieldsEl: null,

    /** @type {?Element} */
    formEl: null,

    /** @type {?Element} */
    errorEl: null,

    /** @type {?Object} */
    formPrepare: null,

    /* @type {Number} */
    requests: 0,

    /**
     * Initialize
     *
     */
    init: function() {
        this.initElements();
        this.initForm();
        this.bindEvents();
    },

    /**
     * Init Elements
     *
     */
    initElements: function() {
        this.customerFieldsEl = this.containerEl.find('.checkout-customer-content');
        this.formEl = this.containerEl.find('.checkout-customer-form');
        this.errorEl = $('.checkout-customer-error');
    },

    /**
     * Initialize Shipping Form
     *
     */
    initForm: function() {
        this.formPrepare = new FormPrepare({
            continueSelector: '[name$="checkoutpersonal_save"]',
            formSelector: '[id$="checkoutpersonal"]'
        });
    },

    /**
     * Bind Events
     *
     * 
     */
    bindEvents: function() {
        var _this = this;

        $(document).off('checkout:customer-updated');
        $(document).on('checkout:customer-updated', this.init.bind(this));
        $(document).off('checkout:update-customer');
        $(document).on('checkout:update-customer', this.update.bind(this));

        // Make sure fields are validated on change and blur (for autocomplete)
        this.formEl.on('change blur', function(event) {
            _this.formPrepare.validateEl(event.target);
        });

        this.formEl.submit(function(event) {
            event.preventDefault();

            if (_this.validate()) {
                document.dispatchEvent(new CustomEvent('GTM-push-checkoutStep', {detail: {
                    step: 1
                }}));
                _this.saveCustomer();
            } else {
                util.scrollBrowser(0);
            }
        });

        // When changing last address field, validate entire form
        this.customerFieldsEl.find('.input-text.required').last().on(
            'change', function() {
                _this.validate();
            });

    },

    /**
     * Validate Entire Form
     *
     * @return {Boolean}
     */
    validate: function() {
        return this.formPrepare.validateForm();
    },

    /**
     * Save Shipping
     *
     */
    saveCustomer: function() {
        var _this = this;
        if (_this.formEl.validate().checkForm()) {
            var disabled = _this.formEl.find(':input:disabled,select:disabled').removeAttr('disabled');
            var data = _this.formEl.serialize();
            disabled.attr('disabled','disabled');
            
                        
            if (data.indexOf('ajax') === -1) {
                data += '&format=ajax';
            }
            $(document).trigger('checkout:step-request', {step: 'customer'});
            $.ajax({
                type: 'POST',
                url: _this.formEl.attr('action'),
                data: data,
                success: function(data) {
                    if ($(data).find('.checkout-customer-error').length > 0) {
                        $(document).trigger(
                            'checkout:handle-response', {
                                step: 'customer', response: data});
                    } else {
                        $(document).trigger(
                            'checkout:handle-response', {
                                step: 'shipping', response: data});
                    }
                    _this.updateSummary();
                }
            });
            
        } else {
            $(document).trigger('checkout:step-dirty', {step: 'customer'});
        }
    },

    /**
     * Update Shipping
     *
     */
    update: function() {
        $(document).trigger('checkout:step-request', {step: 'customer'});
        $.ajax({
            type: 'GET',
            url: this.formEl.attr('action'),
            success: function(data) {
                $(document).trigger(
                    'checkout:handle-response', {
                        step: 'customer', response: data, request: 'GET'});
            }
        });
    },

    /**
     * Update Summary
     *
     */
    updateSummary: function() {
        $(document).trigger('checkout:update-summary');
    }

};

module.exports = Customer;
