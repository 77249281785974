'use strict'

var Accordion = require('../improove/accordion');
var util = require('../util');
var _ = require('lodash');

var ambassador = {

  init: function() {

    var owl = $('.owl-carousel');
    var isCarouselActive = false;
    $(window).on('load resize', _.debounce(function () {
        if (util.getScreenSize() == 'small' && !isCarouselActive) {
            owl.owlCarousel({
              dots: true,
              items: 1,
              loop: true,
              autoplay: true,
              smartSpeed: 500
            });



            isCarouselActive = true;
        } else if (util.getScreenSize() != 'small' && isCarouselActive) {
            owl.trigger('destroy.owl.carousel');
            isCarouselActive = false;
        }
    }, 200));

    new Accordion(
      $('#main').find('.ambassador__accordion'),
        function(){},
        { closeSiblings: true }
    );

  }

}
module.exports = ambassador;
