'use strict';

var imagesLoaded = require('imagesloaded');
var util = require('./util');
var Promise = require('promise');
var minicart = require('./minicart');

function gridViewToggle() {
    $('.toggle-grid').on('click', function () {
        $('.search-result-content').toggleClass('wide-tiles');
        $(this).toggleClass('wide');
    });
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
    gridViewToggle();
    $('.swatch-list').on('mouseleave', function () {
        // Restore current thumb image
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0),
            data = $thumb.data('current');

        $thumb.attr({
            src: data.src,
            alt: data.alt,
            title: data.title
        });
    });
    $('.swatch-list .swatch').on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('selected')) { return; }

        var $tile = $(this).closest('.product-tile');
        $(this).closest('.swatch-list').find('.swatch.selected').removeClass('selected');
        $(this).addClass('selected');
        $tile.find('.thumb-link').attr('href', $(this).attr('href'));
        $tile.find('name-link').attr('href', $(this).attr('href'));

        var data = $(this).children('img').filter(':first').data('thumb');
        var $thumb = $tile.find('.product-image .thumb-link img').eq(0);
        var currentAttrs = {
            src: data.src,
            alt: data.alt,
            title: data.title
        };
        $thumb.attr(currentAttrs);
        $thumb.data('current', currentAttrs);
    }).on('mouseenter', function () {
        // get current thumb details
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0),
            data = $(this).children('img').filter(':first').data('thumb'),
            current = $thumb.data('current');

        // If this is the first time, then record the current img
        if (!current) {
            $thumb.data('current', {
                src: $thumb[0].src,
                alt: $thumb[0].alt,
                title: $thumb[0].title
            });
        }

        // Set the tile image to the values provided on the swatch data attributes
        $thumb.attr({
            src: data.src,
            alt: data.alt,
            title: data.title
        });
    });
}

function sendAddToCartPixelFromPLP (form) {
    try {
        setTimeout(function() {
            var eventName = 'AddToCart';
            var productId = form.find('#pid').val();
            var productQuantity = form.find('.gtmAddToCartQuantity').attr('value');
            if (window.fbAddToCartEventID != undefined) {
                $.ajax({
                    url:Urls.sendFacebookConversion,
                    type:'GET',
                    data: {'fbPixelEventId': window.fbAddToCartEventID, 'eventName': eventName, 'productID': productId, 'productQuantity' : productQuantity},
                    success: function (response) {}
                });
            }
        }, 2000);
    } catch (error) {}
}

function addToCartListener() {
    $('.add-to-cart').off('click').on('click', function (e) {
        e.preventDefault();

        var $form = $(this).closest('form');
    
        addItemToCart($form, $(this)).then(
            function(response) {
                var $uuid = $form.find('input[name="uuid"]');
                if ($uuid.length > 0 && $uuid.val().length > 0) {
                    page.refresh();
                } else {
                    minicart.show(response, e);
                }
            }.bind(this)
        );
    });
}

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
 var addItemToCart = function(form, $button) {
    var $form = $(form),
        $qty = $form.find('input[name="Quantity"]');
    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1');
    }

    $('.add-to-cart').attr('disabled', true);
    $button.find('.add-to-cart-text').addClass('visually-hidden');
    $button.find('.add-to-cart-loading').removeClass('visually-hidden');

    document.dispatchEvent(
        new CustomEvent('GTM-push-addToCart', {
            detail: {
                element: $form.find('.add-to-cart')[0]
            }
        })
    );

    // triggers AddtoCart event for facebook conversion
    if (SitePreferences.FACEBOOK_CONVERSION_ENABLED) {
        sendAddToCartPixelFromPLP(form);
    }
    
    return Promise.resolve(
        $.ajax({
            type: 'POST',
            url: util.ajaxUrl(Urls.addProduct),
            data: $form.serialize()
        })
    ).then(function(response) {
        // handle error in the response
        if (response.error) {
            throw new Error(response.error);
        } else {
            $('.add-to-cart').attr('disabled', false);
            $('.add-to-cart-text').removeClass('visually-hidden');
            $('.add-to-cart-loading').addClass('visually-hidden');
            return response;
        }
    });
};

exports.init = function () {
    var $tiles = $('.tiles-container .product-tile');
    if ($tiles.length === 0) { return; }
    initializeEvents();
    addToCartListener();
};
