'use strict';

var states = require('./improove/states');

exports.init = function init () {

    states.add('country-dropdown', $('.state-container--country-dropdown'), $('.country-dropdown-state-trigger'), {
        onActivate: function () {
            $('html').addClass('country-dropdown-selector');
        },
        onDeactivate: function () {
            $('html').removeClass('country-dropdown-selector');
            $('.country-dropdown__selection-container .dropdown').removeClass('active');

        }
    });

    $('body').on('click', '.dropdown .dropdown-header', function (e) {
        e.preventDefault();
        $(this).closest('.dropdown').toggleClass('active');
    });

    if ($('body').hasClass('state--country-dropdown')) {
        states.activate('country-dropdown', false);
    }

    $('.popular-countries').on('click', '.country', function () {
        var localePaths = $(this)[0].dataset.localepaths.split(' ');
        $(this).addClass('selected');
        $('.active-country')[0].innerHTML = '<a class="title" href="#">' + $(this)[0].textContent || $(this)[0].innerText + '</a>';
        var languages = $(this)[0].dataset.languagestring.split(' ');
        var htmlString = '';
        languages.forEach(function (element, index) {
            if (index === 0) {
                $('.dropdown-header.language-default')[0].innerHTML = '<a class="title" href="#" data-localepath="' + localePaths[index] + '">' + element + '</a>';
            } else {
                htmlString += '<div class="language" data-localepath="' + localePaths[index] + '">' + element + '</div>';
            }
        });
        $('.dropdown-content.language-dropdown')[0].innerHTML = htmlString;

        $('.dropdown.active').each(function (index, element) {
            $(element).removeClass('active');
        });

        if (languages.length < 2) {
            $('.language-default').css('pointer-events', 'none').removeClass('icon-pil-down');
        } else {
            $('.language-default').css('pointer-events', 'all').addClass('icon-pil-down');
        }
    });

    $('.language-dropdown').on('click', '.language', function () {
        var clickedElement = $(this)[0];
        var activeLanguage = $('.language-default .title')[0];

        var defaultLanguage = clickedElement.textContent || clickedElement.innerText;
        clickedElement.textContent = activeLanguage.textContent;
        activeLanguage.textContent = defaultLanguage;

        var localePath = clickedElement.dataset.localepath;
        clickedElement.dataset.localepath = activeLanguage.dataset.localepath;
        activeLanguage.dataset.localepath = localePath;

        $('.dropdown.active').each(function (index, element) {
            $(element).removeClass('active');
        });
        return false;
    });

    $('.country-dropdown__selection-button').click(function () {
        if (window.location.href.indexOf($('.language-default .title')[0].dataset.localepath) < 0) {
            window.location.href = $('.language-default .title')[0].dataset.localepath;
        }

        states.deactivate('country-dropdown', false, false);
    });

};
