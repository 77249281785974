'use strict';

var dialog = require('../../dialog'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    addToCart = require('./addToCart'),
    availability = require('./availability'),
    image = require('./image'),
    productNav = require('./productNav'),
    productSet = require('./productSet'),
    recommendations = require('./recommendations'),
    variant = require('./variant'),
    Accordion = require('../../improove/accordion');

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {
    productNav();
    recommendations();
    tooltip.init();
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
    var $pdpMain = $('#pdpMain');

    addToCart();
    availability();
    variant();
    image();
    productSet();
    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }

    // Initializes Product Detail Accordion
    var onAccordionToggle = function() {

    }

    // Scrolls to the feature with animation when the specific leaf is clicked
    $('.diaper-widget__image a').on('click', function(e){
        e.preventDefault();
        var target = $(this).attr('href'),
            p = $(target).offset().top,
            offset = 75;
        
        p = p - offset;
        $(target).addClass('accordion__item--active');
        $('body, html').animate({'scrollTop': p}, 500);
    });

    // Returns by scrolling up with animation to the main Diaper image with the leafs
    $('.diaper-widget__accordion .accordion__item-heading').on('click', function() {
        var parentAccordion = $(this).parent();
        if (parentAccordion.hasClass('accordion__item--active')) {
            var w = $('.diaper-widget__accordion').offset().top;
            $('html, body').animate({
                scrollTop: (w - 75)
            }, 1000);
        }
    });

    // Expands the pdf file section inside the accordion item
    $('.diaper-widget__accordion .accordion__item-file-link').on('click', function(e) {
        e.preventDefault();
        $(this).next().slideToggle('item-file-expanded');
    });

    // Initializes Accordion on PDP Diaper
    new Accordion(
    	$('body').find('.diaper-widget__accordion'),
        function(){},
        {closeSiblings: false}
    );

    new Accordion(
    	$pdpMain.find('.product-detail__accordion'),
        onAccordionToggle,
        { closeSiblings: false }
    );

    // Increase Product Quantity
    $pdpMain.on('click', '.increase-quantity', function () {
        var quantityInput = $pdpMain.find('.quantity input');

        var quantityValue = parseInt(quantityInput.val());
        var quantityNewValue = quantityValue + 1;

        quantityInput.val(quantityNewValue);
    });

    // Decrease Product Quantity
    $pdpMain.on('click', '.decrease-quantity', function () {
        var quantityInput = $pdpMain.find('.quantity input');

        var quantityValue = parseInt(quantityInput.val());
        var quantityNewValue = 0;

        if(quantityValue == 1) {
            quantityNewValue = quantityValue;
        } else {
            quantityNewValue = quantityValue - 1;
        }

        quantityInput.val(quantityNewValue);
    });

    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="wishlist"], [data-action="gift-registry"]', function () {
        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute('href', url);
    });

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
        e.preventDefault();
    });

    $('.size-chart-link a').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });

    // SmartOrderRefill Option Selected By Default
    if($pdpMain.find('#OsfSor_refill_product_yes').length) {
        $pdpMain.find('#OsfSor_refill_product_yes').prop("checked", true);
    }
}

var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        initializeEvents();
    }
};

module.exports = product;
