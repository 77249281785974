/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

var countries = require('./countries'),
    dialog = require('./dialog'),
    minicart = require('./minicart'),
    page = require('./page'),
    rating = require('./rating'),
    searchplaceholder = require('./searchplaceholder'),
    Searchsuggest = require('./searchsuggest'),
    tooltip = require('./tooltip'),
    util = require('./util'),
    validator = require('./validator'),
    tls = require('./tls'),
    menu = require('./improove/menu'),
    Accordion = require('./improove/accordion'),
    header = require('./improove/header'),
    newsletter = require('./improove/newsletter'),
    windowScroll = require('./improove/windowscroll');

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('./jquery-ext')();
require('./cookieprivacy')();
require('./captcha')();
require('./improove/app');

require('owl.carousel');
require('lazysizes');

function initializeEvents() {
    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];

    $('body')
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        });

    /**
     * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
     * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
     * */
    var $searchContainer = $('.state-container--search .search-form');
    var searchsuggest = new Searchsuggest();
    searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH);

    // add show/hide navigation elements
    $('.secondary-navigation .toggle').click(function () {
        $(this).toggleClass('expanded').next('ul').toggle();
    });

    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();
    $('.toggle').click(function () {
        $(this).toggleClass('expanded').next('.toggle-content').toggle();
    });

    // subscribe email box
    var $subscribeEmail = $('.subscribe-email');
    if ($subscribeEmail.length > 0)    {
        $subscribeEmail.focus(function () {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({color: '#999999'}, 500, 'linear', function () {
                $(this).val('').css('color', '#333333');
            });
        }).blur(function () {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({color: '#333333'}, 500, 'linear');
        });
    }

    $('.privacy-policy').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                height: 600
            }
        });
    });

    // main menu toggle
    $('.menu-category li .menu-item-toggle').on('click', function (e) {
        e.preventDefault();
        var $parentLi = $(e.target).closest('li');
        $parentLi.siblings('li').removeClass('active').find('.menu-item-toggle').removeClass('fa-chevron-up active').addClass('fa-chevron-right');
        $parentLi.toggleClass('active');
        $(e.target).toggleClass('fa-chevron-right fa-chevron-up active');
    });

    // Initializes Accordion on Footer
    new Accordion(
        $('body').find('.footer__accordion'),
        null,
        {closeSiblings: true}
    );

    new Accordion(
        $('body').find('.country_accordion'),
        null,
        {closeSiblings: true}
    );

    // Initializes Header Scroll Animation
    header.init();

    // Initializes Newsletter
    newsletter.init();

    $('#RegistrationFormB2B').on('submit', function(e) {
    
        e.preventDefault();
        var form = $(this);
        $('.form-row .form-caption').removeClass('error-message').html('');
        $.ajax({
            type: 'POST',
            url: form.attr('action'),
            data: form.serialize(),
            success: function(response) {
                var jsonResponse = JSON.parse(response);
                if (jsonResponse.success) {
                    window.location.reload(true);
                } else {
                    if (!jsonResponse.validator) {
                        var invalidElement = $('label[for="dwfrm_profile_customer_' + jsonResponse.invalid + '"]').closest('.form-row').find('.form-caption')

                        if (jsonResponse.invalid === 'passwordconfirm') {
                            invalidElement = $('label[for^="dwfrm_profile_login_passwordconfirm"]').closest('.form-row').find('.form-caption')
                        } 
                        invalidElement.addClass('error-message');
                        invalidElement.html(jsonResponse.invalidText);
                        
                    }
                }
            }
        });
    });

    // Back to Top Button
    $('.back__to__top').on('click', function() {
        util.scrollBrowser(0);
    });
    
    windowScroll.addCallback(function() {
        var scrollTop = $(window).scrollTop();
    
        if (scrollTop > 400) {
            $('.back__to__top').addClass('show');
        } else {
            $('.back__to__top').removeClass('show');
        }
    });

    //Show Notice Banner
    $(document).ready(function() {
        if ($('.notice-banner').length) {
            $('#main').addClass('has-enabled-notice-banner');
        }
    });
}
/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();
}

var pages = {
    account: require('./pages/account'),
    cart: require('./pages/cart'),
    onepagecheckout: require('./pages/checkout/onepage'),
    compare: require('./pages/compare'),
    product: require('./pages/product'),
    registry: require('./pages/registry'),
    search: require('./pages/search'),
    storefront: require('./pages/storefront'),
    wishlist: require('./pages/wishlist'),
    storelocator: require('./pages/storelocator'),
    staticpage: require('./pages/staticpage'),
    ambassador: require('./pages/ambassador'),
    campaignpage: require('./pages/campaignpage')
};

var app = {
    init: function () {
        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }
        initializeDom();
        initializeEvents();

        // init specific global components
        countries.init();
        tooltip.init();
        minicart.init();
        validator.init();
        rating.init();
        searchplaceholder.init();
        menu.init();
        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {

            pages[ns].init();
        }

        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }
    }
};

//Get the cjevent attribute value from the URL.
function getUrlVars()
{
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++)
    {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

//setcookie
/************** Cookie Handling *****************/
function setCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    } else {
        var expires = "";
    }
    var hostName = window.location.hostname.replace('www.', '');
    document.cookie = name + "=" + value + expires + "; path=/; secure; domain=." + hostName;
}

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
 function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

//Store the 'CJEvent' from the URL to the cookie for CJAFFILIATE.
$(document).ready(function() {
    if(getUrlVars().includes("cjevent") && SitePreferences.CJ_Enable) {
        setCookie("cje", getUrlVars()["cjevent"], 396);
    }

    // Handles postback on confirmation page
    if ($('.pt_order-confirmation').length > 0) {
        setTimeout(function() {
    	
    		var orderNo = $('.order-number span.value').html();
    		var cjEvent = getCookie('cje');
    	    $.ajax({
    	        url: Urls.orderConfirmExtraApiUrl,
    	        type: 'get',
    	        dataType: 'json',
    	        data: {
    	        	orderNo: orderNo,
    	        	cjEvent: cjEvent,
                    fbPurchaseEventID: window.fbPurchaseEventID
    	        },
    	        success: function (data) {
    	        },
    	        error: function (err) {
    	        }
    	    });
        }, 1000);
    }
});

// send Initial checkout event to Facebook
try {
    if ($('#checkout-customer').length > 0) {
        if (SitePreferences.FACEBOOK_CONVERSION_ENABLED) {
            if (window.fbInitiateCheckoutEventID != undefined) {
                // calls Facebook-SendFacebookConversion controller
                setTimeout(function() {
                    var eventName = 'InitiateCheckout';
                    if (window.fbInitiateCheckoutEventID != undefined) {
                        $.ajax({
                            url:Urls.sendFacebookConversion,
                            type:'GET',
                            data: {'fbPixelEventId': window.fbInitiateCheckoutEventID, 'eventName': eventName, 'productID': null},
                            success: function (response) {
                            },
                            error: function (err) {
                            }
                        });
                    }
                },2000);
            }
        }
    }
} catch (e) {}


// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

// initialize app
$(document).ready(function () {
    app.init();
});
