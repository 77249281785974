'use strict';

var util = require('./util'),
    states = require('./improove/states');

var currentQuery = null,
    lastQuery = null,
    runningQuery = null,
    listTotal = -1,
    listCurrent = -1,
    delay = 30;

/**
 * @function
 * @description Configures parameters and required object instances
 */
var Searchsuggest = function Searchsuggest() {
    this.$resultsContainer = undefined;

    states.add('search', $('.state-container--search'), $('.search-state-trigger'), {
        onActivate: function() {
            window.setTimeout(function() {
                $('.state-container--search .search-form input[type="text"]').focus();
            }, 100);

            $('.header').addClass('fixed-header');
        },
        onDeactivate: function() {
            var scrollYInit = window.pageYOffset;

            if(scrollYInit == 0) {
                $('.header').removeClass('fixed-header');
            }
        }
    });
}

Searchsuggest.prototype.init = function(container, defaultValue) {
    var $searchContainer = $(container);
    var $searchField = $searchContainer.find('input[name="q"]');

    this.$searchContainer = $searchContainer;
    this.$searchField = $searchField;

    // disable browser auto complete
    $searchField.attr('autocomplete', 'off');

    // on focus listener (clear default value)
    $searchField.focus(function () {
        if (!this.$resultsContainer) {
            // create results container if needed
            this.$resultsContainer = $('<div/>').attr('id', 'search-suggestions').appendTo($searchContainer);
        }
        if ($searchField.val() === defaultValue) {
            $searchField.val('');
        }

        this.initSearch();
    }.bind(this));

    // on key up listener
    $searchField.keyup(function (e) {
        // get keyCode (window.event is for IE)
        var keyCode = e.keyCode || window.event.keyCode;

        // check and treat up and down arrows
        if (this.handleArrowKeys(keyCode)) {
            return;
        }
        // check for an ENTER or ESC
        if (keyCode === 13 || keyCode === 27) {
            this.clearResults();
            return;
        }

        // check for an Backspace or Delete
        if (keyCode === 8 || keyCode === 46) {
            this.clearResults();
        }

        this.initSearch();
    }.bind(this));
};

Searchsuggest.prototype.initSearch = function initSearch() {
    currentQuery = this.$searchField.val().trim();

    // no query currently running, init an update
    if (!runningQuery) {
        runningQuery = currentQuery;
        setTimeout(this.suggest.bind(this), delay);
    }
};

/**
 * @function
 * @description trigger suggest action
 */
Searchsuggest.prototype.suggest = function suggest() {
    // check whether query to execute (runningQuery) is still up to date and had not changed in the meanwhile
    // (we had a little delay)
    if (runningQuery !== currentQuery) {
        // update running query to the most recent search phrase
        runningQuery = currentQuery;
    }

    // if it's empty clear the results box and return
    if (runningQuery.length === 0) {
        this.clearResults();
        runningQuery = null;
        return;
    }

    // if the current search phrase is the same as for the last suggestion call, just return
    if (lastQuery === runningQuery) {
        runningQuery = null;
        return;
    }

    // build the request url
    var reqUrl = util.appendParamToURL(Urls.searchsuggest, 'q', runningQuery);

    // execute server call
    $.get(reqUrl, function (data) {
        var suggestionHTML = data,
            ansLength = suggestionHTML.trim().length;

        // if there are results populate the results div
        if (ansLength === 0) {
            this.clearResults();
        } else {
            // update the results div
            this.$resultsContainer.html(suggestionHTML);
            setTimeout(function() {
                this.$searchContainer.addClass('active-suggestions');
            }.bind(this), 50);
        }

        // record the query that has been executed
        lastQuery = runningQuery;
        // reset currently running query
        runningQuery = null;

        // check for another required update (if current search phrase is different from just executed call)
        if (currentQuery !== lastQuery) {
            // ... and execute immediately if search has changed while this server call was in transit
            runningQuery = currentQuery;
            setTimeout(this.suggest.bind(this), delay);
        }
        this.hideLeftPanel();
    }.bind(this));
};

/**
 * @function
 * @description Handles keyboard's arrow keys
 * @param keyCode Code of an arrow key to be handled
 */
Searchsuggest.prototype.handleArrowKeys = function handleArrowKeys(keyCode) {
    switch (keyCode) {
        case 38:
            // keyUp
            listCurrent = (listCurrent <= 0) ? (listTotal - 1) : (listCurrent - 1);
            break;
        case 40:
            // keyDown
            listCurrent = (listCurrent >= listTotal - 1) ? 0 : listCurrent + 1;
            break;
        default:
            // reset
            listCurrent = -1;
            return false;
    }

    this.$resultsContainer.children().removeClass('selected').eq(listCurrent).addClass('selected');
    $('input[name="q"]').val(this.$resultsContainer.find('.selected .suggestionterm').first().text());
    return true;
}

/**
 * @function
 * @description
 */
Searchsuggest.prototype.clearResults = function clearResults() {
    if (!this.$searchContainer) { return; }
    this.$searchContainer.removeClass('active-suggestions');
    this.$resultsContainer.empty();
};
/**
 * @function
 * @description
 */
Searchsuggest.prototype.hideLeftPanel = function hideLeftPanel() {
    //hide left panel if there is only a matching suggested custom phrase
    if ($('.search-suggestion-left-panel-hit').length === 1 && $('.search-phrase-suggestion a').text().replace(/(^[\s]+|[\s]+$)/g, '').toUpperCase() === $('.search-suggestion-left-panel-hit a').text().toUpperCase()) {
        $('.search-suggestion-left-panel').css('display', 'none');
        $('.search-suggestion-wrapper-full').addClass('search-suggestion-wrapper');
        $('.search-suggestion-wrapper').removeClass('search-suggestion-wrapper-full');
    }
};

module.exports = Searchsuggest;
